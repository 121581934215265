import React, {
  KeyboardEvent,
  FocusEventHandler,
  HTMLAttributes,
  useEffect,
} from 'react';
import cx from 'classnames';

import { MenuDownIcon } from '@mc/wink-icons';
import { ChevronDown } from '@design-systems/icons';
import chainHandlers from '@mc/fn/chainHandlers';
import useId from '@mc/hooks/useId';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import Listbox, { OptionProps as ListboxOptionProps } from '../Listbox';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaLabelledByIds,
} from '../utils';
import { ActionListProps } from '../ActionList';
import emulateSelectKeyboardSearch from './emulateSelectKeyboardSearch';
import stylesheet from './SelectInline.css';
import { TranslateSelect } from './TranslateSelect';
import { OptionProps } from './Option';

const defaultRenderSelectedValue = (
  selected:
    | { children?: React.ReactNode; value: string }
    | { children?: React.ReactNode; value: string }[],
  label: React.ReactNode,
) => {
  const isMultiple = Array.isArray(selected);
  const isSelected = isMultiple ? selected.length > 0 : !!selected;

  if (!isSelected) {
    return label;
  }

  if (isMultiple) {
    const { multipleSelected } = TranslateSelect(selected.length);
    return selected.length > 1
      ? multipleSelected
      : selected.length === 1
      ? selected.map((v) => v.children || v.value)
      : label;
  }

  return selected.children;
};

type SelectInlineListboxTriggerProps = {
  // Filters are only used with inputs.
  filter?: string;
  isExpanded: boolean;
  onBlur: FocusEventHandler;
  // Filters are only used with inputs.
  onFilterChange?: (val?: string) => void;
  onHighlight: (value: string | React.ReactNode) => void;
  onKeyDown: (e: KeyboardEvent) => void;
  onSelect: (selectedValue?: string | number) => void;
  onToggle: () => void;
  options: (OptionProps & ListboxOptionProps)[];
  placeholder?: React.ReactNode;
  renderSelectedValue?: (
    selected: { children?: React.ReactNode; value: string }[],
    placeholder: React.ReactNode,
  ) => string | readonly string[] | number | undefined;
  selected?: { children?: React.ReactNode; value: string }[];
  size?: 'medium' | 'large';
} & HTMLAttributes<HTMLElement>;

const SelectInlineListboxTrigger = React.forwardRef<
  HTMLElement,
  SelectInlineListboxTriggerProps
>(
  (
    {
      selected = [],
      placeholder,
      renderSelectedValue,
      isExpanded,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onFilterChange,
      options,
      onBlur,
      onHighlight,
      onSelect,
      onKeyDown,
      onToggle,
      size = 'medium',
      ...props
    },
    forwardedRef,
  ) => {
    const isRedesignFlagOn = mcdsFlagCheck(
      'xp_mcds_redesign_components_molecules',
    );
    return (
      <React.Fragment>
        <span
          tabIndex={0}
          className={cx(
            stylesheet.selectedValue,
            isRedesignFlagOn && stylesheet[size],
          )}
          {...props}
          ref={forwardedRef}
          onKeyDown={chainHandlers(onKeyDown, (event: KeyboardEvent) => {
            emulateSelectKeyboardSearch(event, {
              options,
              isExpanded,
              onHighlight,
              onSelect,
              onToggle,
            });
          })}
          onClick={onToggle}
          onBlur={onBlur}
        >
          {renderSelectedValue
            ? renderSelectedValue(selected, placeholder)
            : null}
          {isRedesignFlagOn ? (
            <span className={stylesheet.indicator}>
              <ChevronDown size="xsmall" />
            </span>
          ) : (
            <span className={stylesheet.indicator}>
              <MenuDownIcon />
            </span>
          )}
        </span>
      </React.Fragment>
    );
  },
);

export type SelectInlineProps = {
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby'?: string;
  /** Should be children of the `Option` component*/
  children: React.ReactNode;
  /** Makes the input unusable and un-clickable. */
  disabled?: boolean;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** The label of the select. */
  label?: React.ReactNode;
  /** @ignore */
  miscText?: string;
  /** Switches between the two modes:
   * Native: uses a native `<select>` including the native HTML options menu. This is the preferred mode for most Selects.
   * Listbox: Uses a custom implementation of an ARIA listbox. Useful for Selects that need complex options, such as
   * images or styling. Please avoid using listbox mode if your options are plain text.
   */
  mode?: 'native' | 'listbox';
  /** Enables a multiselect. Two important notes about this prop:
   * 1. multiselect will render "listbox" mode even if you have not chosen it in the mode prop (native multiple select is a subpar experience).
   * 2. The value will always be cast to an array onChange so ensure you intend to work with an array (the value can also be null).
   */
  multiple?: boolean;
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: (updatedValue: React.ReactNode) => void;
  /** Placeholder text when no value is selected */
  placeholder?: React.ReactNode;
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly?: boolean;
  /** Controls the size of the select component.
   * Defaults to medium. */
  size?: 'medium' | 'large';
  /** When true, the select will be required to have a value, default will be false */
  required?: boolean;
  /** When true, Listbox will include a search input to filter options. */
  searchable?: boolean;
  /** Override the default display of the selected value in the collapsed select.
   * Defaults to the children of the selected option for single selects, multi selects show a count of selected values. */
  renderSelectedValue?: (
    selected:
      | { children?: React.ReactNode; value: string }
      | { children?: React.ReactNode; value: string }[],
    placeholder: React.ReactNode,
  ) => React.ReactNode;
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value?: string | string[];
} & ActionListProps &
  HTMLAttributes<HTMLElement>;

const SelectInline = React.forwardRef<HTMLSelectElement, SelectInlineProps>(
  function SelectInline(
    {
      'aria-labelledby': ariaLabelledBy,
      className,
      children,
      // miscText isn't being used in this component yet, but in the meantime it needs
      // to be filtered out from the rest props passed to the DOM node.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      miscText,
      mode = 'native',
      multiple = false,
      disabled = false,
      readOnly = false,
      placeholder,
      hideLabel,
      label,
      onChange,
      value,
      renderSelectedValue = defaultRenderSelectedValue,
      size = 'medium',
      required = false,
      searchable = false,
      ...props
    },
    forwardedRef,
  ) {
    useEffect(() => {
      if (!label && !ariaLabelledBy && __DEV__) {
        throw new Error(formatError(ERROR_MUST_PROVIDE_LABEL, 'SelectInline'));
      }
    }, [label, ariaLabelledBy]);

    const id = useId();
    const labelId = useId();

    let selected;
    React.Children.forEach(children, (child) => {
      if ((child as React.ReactElement)?.props.value === value) {
        selected = (child as React.ReactElement).props;
      }
    });
    const isRedesignOrganismFlagOn = mcdsFlagCheck(
      'xp_mcds_redesign_components_organisms',
    );

    const isListbox =
      mode === 'listbox' ||
      multiple ||
      (isRedesignOrganismFlagOn && searchable);

    // We need to handle three cases:
    //
    // 1. Only pass a `label`. Native selects use a native label element, but
    //    Listbox isn't a native select so it must manually use `aria-labelledby`.
    // 2. Only pass an `aria-labelledby`. We don't render a label element.
    // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
    //    `aria-labelledby` attribute.
    const labelledBy = ariaLabelledByIds(
      ariaLabelledBy,
      (ariaLabelledBy || isListbox) && label && labelId,
    );

    const redesigned = mcdsFlagCheck('xp_mcds_redesign_components_molecules');
    const preferredPlacement = redesigned ? 'bottom-start' : 'bottom';
    const preferredOffset = redesigned ? 3 : undefined;

    return (
      <div
        className={cx(stylesheet.root, stylesheet[size], className, {
          [stylesheet.disabled]: disabled,
        })}
      >
        {label && (
          <label
            id={labelId}
            htmlFor={id}
            className={cx(hideLabel && 'wink-visually-hidden')}
          >
            {mcdsFlagCheck('xp_mcds_redesign_components_molecules') &&
              required && (
                // eslint-disable-next-line formatjs/no-literal-string-in-jsx
                <span className={stylesheet.required}>*</span>
              )}
            {label}
          </label>
        )}
        {isListbox ? (
          <Listbox
            className={stylesheet.listbox}
            renderSelectedValue={renderSelectedValue}
            value={value}
            multiple={multiple}
            // @ts-expect-error TS(2739) FIXME: Type 'ForwardRefExoticComponent<SelectInlineListbo... Remove this comment to see the full error message
            trigger={SelectInlineListboxTrigger}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            id={id}
            aria-labelledby={labelledBy}
            onChange={onChange}
            offset={preferredOffset}
            placement={preferredPlacement}
            size={size}
            searchable={isRedesignOrganismFlagOn && searchable}
            {...props}
          >
            {children}
          </Listbox>
        ) : (
          <React.Fragment>
            <select
              value={value}
              disabled={disabled}
              // @ts-expect-error TS(2322) FIXME: Type '{ children: (ReactNode | Element)[]; _?: any... Remove this comment to see the full error message
              readOnly={readOnly}
              id={id}
              aria-labelledby={labelledBy}
              onChange={(event) => onChange(event.target.value)}
              ref={forwardedRef}
              {...props}
            >
              {placeholder && (
                <option value="" disabled>
                  {placeholder}
                </option>
              )}
              {children}
            </select>
            {mcdsFlagCheck('xp_mcds_redesign_components_molecules') ? (
              <span
                data-testid="selectedValue"
                className={cx(stylesheet.selectedValue, stylesheet[size])}
                aria-hidden="true"
              >
                {renderSelectedValue(selected ?? [], placeholder)}
                <span className={stylesheet.indicator}>
                  <ChevronDown size="xsmall" />
                </span>
              </span>
            ) : (
              <span
                data-testid="selectedValue"
                className={stylesheet.selectedValue}
                aria-hidden="true"
              >
                {renderSelectedValue(selected ?? [], placeholder)}
                <span className={stylesheet.indicator}>
                  <MenuDownIcon />
                </span>
              </span>
            )}
          </React.Fragment>
        )}
      </div>
    );
  },
);

export default SelectInline;
