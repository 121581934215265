import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import StackLayout from '../StackLayout';
import Text from '../Text';
import stylesheet from './ContentCard.css';
import Card, { CardImage } from '.';

export type ContentCardProps = {
  /** Card image alt text. */
  alt?: string;
  /** Appearance for card. */
  appearance?: 'basic' | 'default' | 'medium';
  /** Description for card. */
  description?: string;
  /** Subtitle for card. */
  eyebrow?: string;
  /** Horizondal orientation for content card. */
  isHorizondal?: boolean;
  /** Source for card image. */
  src?: string;
  /** Title for card. */
  title?: React.ReactNode;
  /** A background color matching one of the allowed brand colors. */
  variant?:
    | 'coconut'
    | 'cornflower'
    | 'cucumber'
    | 'radish'
    | 'squash'
    | 'yam'
    | 'jasmine';
} & HTMLAttributes<HTMLDivElement>;

const ContentCard = React.forwardRef<HTMLDivElement, ContentCardProps>(
  function ContentCard(
    {
      alt,
      variant = 'cornflower',
      src,
      eyebrow,
      title,
      description,
      appearance = 'medium',
      isHorizondal,
    },
    forwardedRef,
  ) {
    if (mcdsFlagCheck('xp_mcds_redesign_components_organisms')) {
      return (
        <Card
          appearance="none"
          className={cx(stylesheet.root, {
            [stylesheet.isHorizondal]: isHorizondal,
          })}
          ref={forwardedRef}
        >
          {src && (
            <CardImage
              appearance={appearance}
              src={src}
              variant={variant}
              alt={alt}
              className={stylesheet.imgWrapper}
            />
          )}
          <div className={stylesheet.content}>
            <Text className={stylesheet.eyebrow}>{eyebrow}</Text>
            {React.isValidElement(title)
              ? React.cloneElement(title, {
                  className: stylesheet.targetLink,
                })
              : title}
            <Text className={stylesheet.description}>{description}</Text>
          </div>
        </Card>
      );
    }

    return (
      <Card appearance="none" className={stylesheet.root} ref={forwardedRef}>
        <CardImage
          appearance={appearance}
          src={src}
          variant={variant}
          alt={alt}
        />
        <div className={stylesheet.content}>
          <StackLayout gap={0}>
            <Text appearance="medium-bold" className={stylesheet.eyebrow}>
              {eyebrow}
            </Text>
            {React.isValidElement(title)
              ? React.cloneElement(title, {
                  className: stylesheet.targetLink,
                })
              : title}
            <Text>{description}</Text>
          </StackLayout>
        </div>
      </Card>
    );
  },
);

export default ContentCard;
